var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ma-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.$store.state.list = !_vm.$store.state.list}}},[(_vm.$store.state.list)?_c('v-icon',[_vm._v("mdi-apps")]):_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)],1),(_vm.$store.state.list)?_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"11","lg":"10"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"items":_vm.$store.state.sodas,"items-per-page":20,"headers":_vm.headers,"fixed-header":"","search":_vm.search,"height":"70vh"},on:{"click:row":_vm.openProduct},scopedSlots:_vm._u([{key:"item.quantityRemaining",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":_vm.quantityColor(item.quantityRemaining),"height":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true),model:{value:(item.quantityRemaining),callback:function ($$v) {_vm.$set(item, "quantityRemaining", $$v)},expression:"item.quantityRemaining"}})]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2",attrs:{"size":"80"}},[_c('v-img',{staticClass:"liquid",attrs:{"src":item.logo}})],1)]}},{key:"item.cups",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.quantityRemaining * 5)+" ("+_vm._s(item.size.amount)+" "+_vm._s(item.size.type)+")")])]}}],null,false,4220156816)})],1)],1):_c('v-row',{staticClass:"text-center mx-auto"},[_vm._l((_vm.$store.state.sodas),function(item){return _c('v-col',{key:item.i,staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('soda',{attrs:{"soda":item}})],1)}),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('soda',{attrs:{"empty":true}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }